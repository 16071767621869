import { useRollbar } from '@rollbar/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import { InternalError } from 'shared/errors/internal-error'
import useOnScreen from 'shared/hooks/use-on-screen'
import useUser from 'shared/hooks/use-user'
import LoadingIcon from 'shared/icons/loading-icon'
import { CommentsCountByPostIds } from 'shared/types/comments-count-by-post-ids'
import { baseServerSideProps } from 'shared/utils/base-server-side-props'
import { fetchCommentsCount } from '../../../modules/community/api/comment-api'
import MobileNav from '../../../modules/header/components/mobile-nav'
import { likePost, unlikePost } from 'modules/community/api/community-api'
import * as postsApi from 'modules/community/api/posts-api'
import AddPost from 'modules/community/components/add-post'
import PostAnnounce, { PostAnnounceSkeleton } from 'modules/community/components/post-announce'
import { ScrollToTopButton } from 'modules/community/components/scroll-to-top-button'
import { useCommunityWithTopics } from 'modules/community/hooks/use-community-with-topics'
import useIsUserMember from 'modules/community/hooks/use-is-user-member'
import { PostInterface } from 'modules/community/types/post-interface'
import { getPostActions } from 'modules/post/utils/post-utils'

function Community() {
  const { t } = useTranslation()
  const rollbar = useRollbar()
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)
  const [isLoading, setLoading] = useState(false)
  const [isEnd, setIsEnd] = useState(false)
  const [lastPostId, setLastPostId] = useState(0)
  const router = useRouter()
  const { user } = useUser()
  const { isUserMember } = useIsUserMember()
  const { communityPath } = router.query
  const { data: community } = useCommunityWithTopics()
  const { mutate, data: posts = [] } = useSWR<PostInterface[]>('posts')
  const { mutate: mutateCommentsCountByPostIds, data: commentsCountByPostIds = {} } =
    useSWR<CommentsCountByPostIds>('commentsCountByPostIds')
  const [postsIds, setPostsIds] = useState<number[]>([])

  const loadPosts = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await postsApi.getPostsByLastId(communityPath as string, lastPostId)
      const lastPost = data.items.at(-1)
      if (lastPost) {
        setLastPostId(lastPost.id)
      }
      if (!data.hasMore) {
        setIsEnd(true)
      }
      await mutate((prevPosts = []) => [...prevPosts, ...data.items])
      setPostsIds([...postsIds, ...data.items.map(post => post.id)])
      setLoading(false)
    } catch (e) {
      setIsEnd(true)
      toast.error(t('core.error.internal_error_message'))
      setLoading(false)
    }
  }, [communityPath, lastPostId, mutate, t])

  async function loadCommentsCount(postsIds: number[]) {
    try {
      const { data } = await fetchCommentsCount(communityPath as string, postsIds)
      const newCommentsCountByPostIds: CommentsCountByPostIds = {}
      data.forEach(commentsCount => {
        newCommentsCountByPostIds[commentsCount.communityPostId] = commentsCount.commentsCount
      })
      await mutateCommentsCountByPostIds({
        ...commentsCountByPostIds,
        ...newCommentsCountByPostIds,
      })
    } catch (e) {
      if (e instanceof InternalError) {
        rollbar.error(e)
      } else {
        rollbar.log(JSON.stringify(e))
      }
      console.log(`--e--`, e)
    }
  }

  const handleLikeClick =
    ({ id, topic, path, isLiked }: PostInterface) =>
    async () => {
      const { globalMutatePost } = getPostActions({
        communityPath: communityPath as string,
        topicPath: topic.path,
        postPath: path,
      })

      try {
        if (isLiked) {
          await unlikePost(id)
          await mutate(
            data =>
              data &&
              data.map(post =>
                post.id === id
                  ? { ...post, isLiked: false, likesCount: post.likesCount - 1 }
                  : post,
              ),
            false,
          )
          globalMutatePost(
            post => post && { ...post, isLiked: false, likesCount: post?.likesCount - 1 },
          )
        } else {
          await likePost(id)
          await mutate(
            data =>
              data &&
              data.map(post =>
                post.id === id ? { ...post, isLiked: true, likesCount: post.likesCount + 1 } : post,
              ),
            false,
          )
          globalMutatePost(
            post => post && { ...post, isLiked: true, likesCount: post?.likesCount + 1 },
          )
        }
      } catch (err) {
        toast.error(t('post_details.error.error_like_post'))
      }
    }

  useEffect(() => {
    setLastPostId(0)
    setIsEnd(false)
    mutate(() => [])
  }, [])

  useEffect(() => {
    if (isVisible && !isLoading && community && !isEnd && user) {
      loadPosts()
    }
  }, [isVisible, community, isLoading, isEnd, user])

  useEffect(() => {
    if (postsIds.length > 0) {
      loadCommentsCount(postsIds)
    }
  }, [postsIds])

  function deletePost(postId: number) {
    return async () => {
      await postsApi.deletePost(postId)
      const filteredPosts = posts.filter(p => p.id !== postId)
      await mutate(filteredPosts, false)
    }
  }

  function deletePostAndBanUser(postId: number, userId: number) {
    return async () => {
      await postsApi.banUser(communityPath as string, userId)
      await postsApi.deletePost(postId)
      const filteredPosts = posts.filter(p => p.id !== postId)
      await mutate(filteredPosts, false)
    }
  }

  return (
    <>
      <MobileNav />
      <div>
        {isUserMember && <AddPost />}
        {isLoading && posts.length === 0 && (
          <>
            <PostAnnounceSkeleton key={1} />
            <PostAnnounceSkeleton key={2} />
          </>
        )}
        {posts.map(post => (
          <PostAnnounce
            key={post.id}
            deletePost={deletePost(post.id)}
            deletePostAndBanUser={deletePostAndBanUser(post.id, post.userId)}
            post={post}
            commentsCount={commentsCountByPostIds[post.id]}
            handleLikeClick={handleLikeClick(post)}
            dashboardLocale={user?.dashboardLocale}
          />
        ))}
        {isLoading && posts.length > 0 && (
          <div className="flex justify-center">
            <LoadingIcon />
          </div>
        )}
        <div ref={ref} />
      </div>
      <ScrollToTopButton />
    </>
  )
}

export const getServerSideProps = baseServerSideProps

export default Community
